<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                    <b-row>
                      <b-col lg="11" sm="12">
                        <ValidationProvider name="Grant ID" vid="grant_id" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="3"
                              label-for="grant_id"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                              {{ $t('barc_config.grant_id') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                              plain
                              v-model="budgetPolicySetup.grant_id"
                              :options="grantList"
                              id="grant_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="Budget Policy Year" vid="fiscal_year_id" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="3"
                              label-for="fiscal_year_id"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                              {{ $t('warehouse_config.fiscal_year') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                              plain
                              v-model="budgetPolicySetup.fiscal_year_id"
                              :options="fiscalList"
                              id="fiscal_year_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="Maximum Allocation Amount" vid="max_allo_amount" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="3"
                              label-for="max_allo_amount"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                              {{ $t('barc_config.maximum_allocation_amount') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                :id="max_allo_amount"
                                v-model="budgetPolicySetup.max_allo_amount"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                          </b-form-group>
                        </ValidationProvider>
                        <b-row>
                          <b-col lg="12" sm="12" class="mb-2" style="margin:10px 0;">
                              <h5 style="font-weight:bold">
                                  {{ $t('barc_config.item_add_for_budget_policy') }}
                                  <b-button v-if="totalAttachment > 0" class="btn-sm">Total Added : {{ totalAttachment }}</b-button>
                              </h5>
                          </b-col>
                        </b-row>
                        <b-row style="border-bottom:1px solid #ddd; margin-bottom:10px">
                            <b-col lg="3">
                                <p class="text-dark">{{ $t('barc_config.item_name') }} <span class="text-danger">*</span></p>
                            </b-col>
                            <b-col lg="4">
                                <p class="text-dark">{{ $t('barc_config.maximum_amount_in_taka') }} <span class="text-danger">*</span></p>
                            </b-col>
                            <b-col lg="4">
                                <p class="text-dark">{{ $t('barc_config.maximum_amount_percentage(%)') }} <span class="text-danger">*</span></p>
                            </b-col>
                            <b-col lg="1">
                                <p class="text-dark">{{ $t('globalTrans.action') }}</p>
                            </b-col>
                        </b-row>
                        <b-row v-for="(wordn,index) in budgetPolicySetup.budget_policy_details" :key="index">
                          <b-col xl="3" lg="3" sm="8">
                              <ValidationProvider name="Item Name" vid="item_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-for="item_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <b-form-select
                                    plain
                                    v-model="wordn.item_id"
                                    :options="itemList"
                                    id="index"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                              </ValidationProvider>
                          </b-col>
                          <b-col xl="4" lg="4" sm="8">
                              <ValidationProvider name="Maximum Amount in Taka" vid="max_amount_taka" rules="required">
                                  <b-form-group
                                  class="row"
                                  label-for="max_amount_taka"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <b-form-input
                                      :id="index"
                                      v-model="wordn.max_amount_taka"
                                      @change="getPercentage(index)"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      ></b-form-input>
                                  <div class="invalid-feedback">
                                      {{ errors[0] }}
                                  </div>
                                  </b-form-group>
                              </ValidationProvider>
                          </b-col>
                          <b-col xl="4" lg="4" sm="8">
                              <ValidationProvider name="Maximum Amount Percentage(%)" vid="max_amount_percent" rules="required">
                                  <b-form-group
                                  class="row"
                                  label-for="max_amount_percent"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <b-form-input
                                      readonly
                                      ml="2"
                                      :id="index"
                                      v-model="wordn.max_amount_percent"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      ></b-form-input>
                                  <div class="invalid-feedback">
                                      {{ errors[0] }}
                                  </div>
                                  </b-form-group>
                              </ValidationProvider>
                          </b-col>
                          <b-col xl="1" lg="1" sm="4">
                              <b-button v-show="index == budgetPolicySetup.budget_policy_details.length-1" variant=" iq-bg-success" size="sm" @click="add()"><i class="ri-add-line m-0"></i></b-button>
                              <b-button v-show="index || ( !index && budgetPolicySetup.budget_policy_details.length > 1)" variant=" iq-bg-danger" size="sm" @click="remove(index)"><i class="ri-delete-bin-line m-0"></i></b-button>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                          &nbsp;
                          <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { budgetPolicySetupStore, budgetPolicySetupUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getBudgetPolicySetupData()
      this.budgetPolicySetup = tmp
      // const detailsInfo = tmp.budget_policy_details.map(item => {
      //   return { class_or_grade_id: item.class_or_grade_id, point: item.point }
      // })
      // this.budgetPolicySetup.budget_policy_details = detailsInfo
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      totalPost: 0,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      budgetPolicySetup: {
        fiscal_year_id: '0',
        grant_id: '0',
        max_allo_amount: '',
        budget_policy_details: [
          {
              item_id: '0',
              max_amount_taka: '',
              max_amount_percent: ''
              // max_amount_percent: this.budgetPolicySetup.max_allo_amount / this.budgetPolicySetup.budget_policy_details.max_amount_taka
          }
        ]
      },
      classList: [],
      gradeList: []
    }
  },
  computed: {
    fiscalList: function () {
      const fiscalyearData = this.$store.state.commonObj.fiscalYearList
      return fiscalyearData.map(item => {
          if (this.$i18n.locale === 'bn') {
              return { value: item.value, text: item.text_bn }
          } else {
              return { value: item.value, text: item.text_en }
          }
      })
    },
    grantList: function () {
      const objectData = this.$store.state.incentiveGrant.commonObj.grantList
      return objectData.map(obj => {
          if (this.$i18n.locale === 'bn') {
              return { value: obj.value, text: obj.text_bn }
          } else {
              return { value: obj.value, text: obj.text_en }
          }
      })
    },
    itemList: function () {
      const objectData = this.$store.state.incentiveGrant.commonObj.itemList
      return objectData.map(obj => {
          if (this.$i18n.locale === 'bn') {
              return { value: obj.value, text: obj.text_bn }
          } else {
              return { value: obj.value, text: obj.text_en }
          }
      })
    }
  },
  methods: {
    getBudgetPolicySetupData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    getPercentage (index) {
      const itemTaka = this.budgetPolicySetup.budget_policy_details[index].max_amount_taka
      const percentage = (itemTaka / this.budgetPolicySetup.max_allo_amount) * 100
      this.budgetPolicySetup.budget_policy_details[index].max_amount_percent = percentage
    },
    async saveData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

      if (this.id) {
          result = await RestApi.putData(incentiveGrantServiceBaseUrl, `${budgetPolicySetupUpdate}/${this.id}`, this.budgetPolicySetup)
      } else {
          result = await RestApi.postData(incentiveGrantServiceBaseUrl, budgetPolicySetupStore, this.budgetPolicySetup)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
          this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
          this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
          })

          this.$bvModal.hide('modal-4')
      } else {
          this.$refs.form.setErrors(result.errors)
      }
    },
    add () {
      const tampbpSetup = {
        item_id: '0',
        max_amount_taka: '',
        max_amount_percent: ''
      }
      const key1 = parseInt(this.budgetPolicySetup.budget_policy_details.length - 1)
      const item = this.budgetPolicySetup.budget_policy_details[key1]
      let isEmpty = true
      Object.keys(item).map(key => {
          if (item[key] === '' || item[key] === 0) {
              isEmpty = false
          }
      })
      if (isEmpty === true) {
          this.budgetPolicySetup.budget_policy_details.push(tampbpSetup)
      }
    },
    remove (key) {
        this.budgetPolicySetup.budget_policy_details.splice(key, 1)
    }
  }
}
</script>
